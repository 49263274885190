import React from 'react';
import CVATTooltip from 'components/common/cvat-tooltip';
import Icon from '@ant-design/icons';
import { message } from 'antd';
import axios from 'axios';
import { DownloadIcon } from 'icons';

const SaveImageControl: React.FC = () => {
    const downloadZIP = async () => {
        try {
            // Send a GET request to the Flask backend to download the ZIP file
            const response = await axios.get('http://localhost:8080/download', {
                responseType: 'blob', // Important for handling binary files
            });

            // Create a Blob from the response data
            const blob = new Blob([response.data], { type: 'application/zip' });

            // Create a temporary link element
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'annotations.zip'; // File name for the downloaded ZIP
            document.body.appendChild(link); // Append the link to the document
            link.click(); // Trigger the download
            document.body.removeChild(link); // Remove the link after download

            message.success('ZIP file downloaded successfully!');
        } catch (error) {
            console.error('Error downloading the ZIP file:', error);
            message.error('Failed to download the ZIP file. Please try again.');
        }
    };

    return (
        <CVATTooltip title='Download ZIP'>
            <Icon
                className='cvat-fit-control'
                component={DownloadIcon}
                onClick={downloadZIP}
                style={{ cursor: 'pointer' }}
            />
        </CVATTooltip>
    );
};

export default SaveImageControl;
