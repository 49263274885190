//
import Icon from '@ant-design/icons';
import { getJobReport, saveJobReport, showErrorModal, toggleReportEditor } from 'actions/annotation-actions';
import { Button, Select, Spin } from 'antd';
import 'antd/dist/antd.css';
import { getCore } from 'cvat-core-wrapper';
import { ReportIcon } from 'icons';
import React, { useCallback, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { connect, useDispatch } from 'react-redux';
import { CombinedState } from 'reducers';
import { formatDate } from 'utils/misc';
import { Resizable } from 're-resizable';
import CVATTooltip from 'components/common/cvat-tooltip';
import MammogramForm from './report-editor-form';

enum LambdaReportHandlerActions {
    GENERATE_REPORT = 'generateReport',
    GENERATE_FROM = 'generateForm',
}

interface ModalOpenerProps {
    children: React.ReactNode;
    triggerText: string;
    onSave(): Promise<any>;
    onOpen(): void;
    footerExtension: React.ReactElement[];
}

interface ResizableContainerProps {
    children: React.ReactNode;
    className: string;
    style: React.CSSProperties;
}

const ResizableContainer = (props: ResizableContainerProps) => {
    const [size, setSize] = useState({ width: 600, height: '100%' });

    const handleResize = (event: any, direction: any, ref: any, delt: any) => {
        setSize({
            width: ref.style.width,
            height: ref.style.height,
        });
    };

    return (
        <Resizable
            as='aside'
            size={size}
            onResize={handleResize}
            maxWidth='100%'
            maxHeight='100%'
            className={props.className}
            style={props.style}
        >
            {props.children}
        </Resizable>
    );
};

/* opens and closes the editor by dispatching the action */
const ReportEditorControl: React.FC<ModalOpenerProps> = (props: any) => {
    const { triggerText } = props;
    // eslint-disable
    const dispatch = useDispatch();
    const show = () => {
        dispatch(toggleReportEditor());
    };

    return (
        <CVATTooltip title='Report Editor'>
            <Icon className='cvat-fit-control' component={ReportIcon} onClick={show}>
                {triggerText}
            </Icon>
        </CVATTooltip>
    );
};

interface MarkdownEditorProps {
    onChange(markdown: string): void;
    content: string;
}

const MarkdownEditor = (props: MarkdownEditorProps) => {
    const { onChange, content } = props;
    const handleChange = (...args: any[]) => {
        onChange(args[0]); //args[0] is the content
    };

    return (
        <ReactQuill
            className='cvat-report-editor'
            value={content}
            onChange={handleChange}
            theme='snow'
            modules={{
                toolbar: [
                    [{ header: '1' }, { header: '2' }, { font: ['Serif'] }],
                    [{ size: [] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    ['link', 'image', 'video'],
                ],
            }}
            formats={[
                'header',
                'font',
                'size',
                'bold',
                'italic',
                'underline',
                'strike',
                'blockquote',
                'list',
                'bullet',
                'indent',
                'link',
                'image',
                'video',
            ]}
        />
    );
};

const mapStateToProps = (state: CombinedState) => {
    /* window.FORM_GENERATOR_MODEL & window.REPORT_GENERATOR_MODEL are being used
     in development to set the lambda function names in browser console
     @ts-ignore */
    const [REPORT_GENERATOR_MODEL, FORM_GENERATOR_MODEL] = [window.REPORT_GENERATOR_MODEL, window.FORM_GENERATOR_MODEL];

    const reportGeneratorModel =
        REPORT_GENERATOR_MODEL || state.models.detectors.find((m) => m.id === 'pth-ultralytics-report-table-test');
    const fromGeneratorModel =
        FORM_GENERATOR_MODEL || state.models.detectors.find((m) => m.id === 'pth-ultralytics-report-table-test');
    const { instance } = state.annotation.job;
    const { editReport } = state.annotation;
    return { editReport, reportGeneratorModel, fromGeneratorModel, jobInstance: instance };
};

function mapDispatchToProps(dispatch: any) {
    const closeEditor = () => dispatch(toggleReportEditor());
    const showError = (error: Error) => dispatch(showErrorModal(error));
    //TODO: replace showError with direct in component dispatching
    return {
        dispatch,
        closeEditor,
        showError,
    };
}

interface VersionListResponse {
    id: number;
    username: string;
    is_autogenerated: boolean;
    version: number;
    created_at: string;
}

function ReportEditorSection(props: any): React.ReactElement {
    const { reportGeneratorModel, fromGeneratorModel, jobInstance, dispatch, editReport, closeEditor } = props;
    const jobId = jobInstance.id;

    const [state, setState] = useState({
        mdContent: '',
        isLoading: {
            spinning: false,
            generating: false,
            fetching: true,
        },
        options: [{ label: 'new version', value: 1 }],
        editing: true,
        formData: {} as any,
        transition: false,
    });

    const { mdContent, isLoading, options } = state;

    useEffect(() => {
        if (editReport) {
            fetchInitialReport();
        }
    }, []);

    const setLoadingState = (key: keyof typeof isLoading, value: boolean) => {
        setState((prevState) => ({
            ...prevState,
            isLoading: {
                ...prevState.isLoading,
                [key]: value,
            },
        }));
    };

    const fetchInitialReport = async () => {
        try {
            const report = await getJobReport(jobId, undefined, false);
            setState((prevState) => ({
                ...prevState,
                mdContent: report,
            }));
            fetchVersionsList();
        } catch (error) {
            console.error('Error fetching initial report:', error);
        }
    };

    const fetchVersionsList = async () => {
        try {
            const report = await getJobReport(jobId, undefined, true);
            const versions = processArray(report);
            setState((prevState) => ({
                ...prevState,
                options: versions,
            }));
        } catch (error) {
            console.error('Error fetching versions list:', error);
        } finally {
            setLoadingState('fetching', false);
        }
    };

    const processArray = (arr: VersionListResponse[]): { label: string; value: number }[] => {
        return arr.map((item) => ({
            label: `${item.username} ${formatDate(item.created_at)}`,
            value: item.id,
        }));
    };

    const handleMarkdownContent = useCallback((e: string) => {
        setState((prevState) => ({
            ...prevState,
            mdContent: e,
        }));
    }, []);

    const generateFormData = async () => {
        const frames: Record<number, string> = {};
        const frameNameKeywords = ['LCC', 'LMLO', 'RCC', 'RMLO'];

        for (let i = 0; i < jobInstance.frameCount; i++) {
            const data = await jobInstance.frames.get(i);
            for (const keyword of frameNameKeywords) {
                if (data.filename.toUpperCase().includes(keyword)) {
                    frames[i] = keyword;
                }
            }
        }
        try {
            const response = await getCore().lambda.call(jobInstance.taskId, fromGeneratorModel, {
                frame: 0,
                frames: frames,
                job: jobId,
                data: { action: LambdaReportHandlerActions.GENERATE_FROM },
                action: LambdaReportHandlerActions.GENERATE_FROM,
            });
            console.log(response);
            return response[0]['form'];
        } catch (error) {
            console.error('Error generating report:', error);
            dispatch(showErrorModal(error));
            throw error;
        }
    };

    // call lambda with form data and gets report
    const generateReport = async (formData: any) => {
        try {
            const response = await getCore().lambda.call(jobInstance.taskId, reportGeneratorModel, {
                frame: 0,
                job: jobId,
                data: { ...formData, action: LambdaReportHandlerActions.GENERATE_REPORT },
                action: LambdaReportHandlerActions.GENERATE_REPORT,
            });
            return response[0]['report'];
        } catch (error) {
            console.error('Error generating report:', error);
            dispatch(showErrorModal(error));
            throw error;
        }
    };

    const handleReportFormData = async () => {
        setLoadingState('generating', true);
        try {
            let labmdaResponse = await generateFormData();
            setState((prevState) => ({
                ...prevState,
                editing: false,
                formData: labmdaResponse,
            }));
        } catch (error) {
            dispatch(showErrorModal(error));
        } finally {
            setLoadingState('generating', false);
        }
    };

    const handleAIAssist = async (data: any, submitStateSetter: CallableFunction) => {
        try {
            submitStateSetter(true);
            const report = await generateReport(data);

            setState((prevState) => ({
                ...prevState,
                mdContent: report,
            }));
        } catch (error) {
            dispatch(showErrorModal(error));
        } finally {
            setLoadingState('generating', false);
            submitStateSetter(true);
            setState((prevState) => ({
                ...prevState,
                editing: true,
            }));
        }
    };

    const handleSave = async (jobId: number, content: string) => {
        setLoadingState('spinning', true);
        try {
            await saveJobReport(jobId, content);
        } catch (error) {
            console.error('Error saving report:', error);
            dispatch(showErrorModal(error));
        } finally {
            setLoadingState('spinning', false);
        }
    };

    const handleVersionChange = async (versionNumber: number) => {
        try {
            const reportVersion = await getJobReport(jobId, versionNumber, false);
            handleMarkdownContent(reportVersion);
        } catch (error) {
            console.error('Error changing version:', error);
        }
    };
    const footer = (
        <Button.Group className='report-editor-button-group'>
            <Button key='back' type='primary' onClick={closeEditor}>
                Close
            </Button>
            <Button
                loading={isLoading.spinning}
                key='submit'
                type='primary'
                onClick={() => handleSave(jobId, mdContent)}
            >
                Save
            </Button>
            <Button
                disabled={!reportGeneratorModel || !fromGeneratorModel}
                loading={isLoading.generating}
                key='ai-assist'
                type='primary'
                onClick={handleReportFormData}
            >
                AI Assist
            </Button>
        </Button.Group>
    );

    const Editor = (
        <>
            {isLoading.fetching ? (
                <Spin spinning className='version-select-spin' />
            ) : (
                <Select
                    defaultValue={options[0]?.label}
                    style={{ width: '240px' }}
                    options={options}
                    onChange={(value) => handleVersionChange(parseInt(value))}
                />
            )}
            <MarkdownEditor content={mdContent} onChange={handleMarkdownContent} />
            {footer}
        </>
    );

    return (
        <ResizableContainer
            className='cvat-report-editor-container'
            style={{ overflowY: !state.editing ? 'scroll' : 'clip' }}
        >
            {state.editing && Editor}
            {!state.editing && <MammogramForm formData={state.formData} sendFormData={handleAIAssist} />}
        </ResizableContainer>
    );
}
export const ReportEditorComponent = connect(mapStateToProps, mapDispatchToProps)(ReportEditorSection);

export default ReportEditorControl;
const getSampleData = () => {
    return {
        leftBreast: {
            lymphNodes: [{ location: '3:00', type: 'Intramammary Benign' }],
            breastComposition: 'scatteredFibroglandular',
            calcifications: [{ location: '3:00', size: 10, morphology: 'A', distribution: 'Diffuse' }],
            masses: [{ location: '3:00', size: 5 }],
            asymmetry: [{ location: '2', type: 'globalAsymmetry' }],
            birads: '3',
        },
        rightBreast: {
            lymphNodes: [{ location: '3:00', type: 'Intramammary Benign' }],
            breastComposition: 'heterogeneouslyDense',
            calcifications: [],
            masses: [],
            asymmetry: [],
            birads: '4',
        },
    };
};
