// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Icon from '@ant-design/icons';

import { RulerIcon } from 'icons';
import { Canvas, CuboidDrawingMethod, RectDrawingMethod } from 'cvat-canvas-wrapper';
import { Canvas3d } from 'cvat-canvas3d-wrapper';
import { ObjectType, ShapeType } from 'reducers';
import { filterByName } from 'utils/filter-applicable-labels';
import config from 'config';
import { rememberObject, showErrorModal } from 'actions/annotation-actions';
import CVATTooltip from 'components/common/cvat-tooltip';
import { connect } from 'react-redux';
import { Label } from 'cvat-core-wrapper';

interface DispatchToProps {
    onDrawStart(
        shapeType: ShapeType,
        labelID: number,
        objectType: ObjectType,
        points?: number,
        rectDrawingMethod?: RectDrawingMethod,
        cuboidDrawingMethod?: CuboidDrawingMethod,
    ): void;
    onError(message: string): void;
}

interface StateToProps {
    normalizedKeyMap: Record<string, string>;
    canvasInstance: Canvas | Canvas3d;
    shapeType: ShapeType;
    labels: any[];
    jobInstance: any;
}
type Props = StateToProps & DispatchToProps;

interface State {
    rectDrawingMethod?: RectDrawingMethod;
    cuboidDrawingMethod?: CuboidDrawingMethod;
    numberOfPoints?: number;
    selectedLabelID: number | null;
}
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        onDrawStart(
            shapeType: ShapeType,
            labelID: number,
            objectType: ObjectType,
            points?: number,
            rectDrawingMethod?: RectDrawingMethod,
            cuboidDrawingMethod?: CuboidDrawingMethod,
        ): void {
            dispatch(
                rememberObject({
                    activeObjectType: objectType,
                    activeShapeType: shapeType,
                    activeLabelID: labelID,
                    activeNumOfPoints: points,
                    activeRectDrawingMethod: rectDrawingMethod,
                    activeCuboidDrawingMethod: cuboidDrawingMethod,
                }),
            );
        },
        onError(message: string) {
            dispatch(showErrorModal(message));
        },
    };
}

export function mapStateToProps(state: any, own: any): StateToProps {
    const {
        annotation: {
            canvas: { instance: canvasInstance },
            job: { labels, instance: jobInstance },
        },
        shortcuts: { normalizedKeyMap },
    } = state;

    return {
        ...own,
        canvasInstance: canvasInstance as Canvas,
        labels,
        normalizedKeyMap,
        jobInstance,
    };
}

class DrawRulerControl extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.setRulerData();
        const { shapeType } = props;

        const defaultRectDrawingMethod = RectDrawingMethod.CLASSIC;
        const defaultCuboidDrawingMethod = CuboidDrawingMethod.CLASSIC;
        this.state = {
            rectDrawingMethod: shapeType === ShapeType.RECTANGLE ? defaultRectDrawingMethod : undefined,
            cuboidDrawingMethod: shapeType === ShapeType.CUBOID ? defaultCuboidDrawingMethod : undefined,
        };
    }

    private setRulerData(): void {
        let pixelSize = { x: 1, y: 1 };
        const { canvasInstance, labels, onError } = this.props;
        const pixelSizeList = filterByName(labels, config.PIXEL_SIZE_LABEL_NAME);
        if (pixelSizeList) {
            try {
                const pixleSizeLabel = pixelSizeList[0];
                pixelSize.x = parseFloat(filterByName(pixleSizeLabel.attributes, 'x')[0].values[0]);
                pixelSize.y = parseFloat(filterByName(pixleSizeLabel.attributes, 'y')[0].values[0]);
            } catch (error) {
                pixelSize = { x: 0, y: 0 };
                onError('Ruler data is not correct!');
            }
        }
        // @ts-ignore: setPixelSize exists on Canvas
        canvasInstance.setPixelSize(pixelSize);
    }
    private onDraw = (): void => {
        const { canvasInstance, shapeType, onDrawStart, labels } = this.props;
        const { rectDrawingMethod, cuboidDrawingMethod } = this.state;
        const numberOfPoints = 2    ;

        canvasInstance.cancel(); // Cancel any ongoing drawing.

        const selectedLabel = labels[0];
        canvasInstance.draw({
            enabled: true,
            rectDrawingMethod: undefined,
            cuboidDrawingMethod: undefined,
            numberOfPoints,
            shapeType: ShapeType.POLYLINE,
            skeletonSVG: undefined,
            crosshair: true,
        });

        onDrawStart(
            shapeType,
            selectedLabel.id,
            ObjectType.SHAPE,
            numberOfPoints,
            rectDrawingMethod,
            cuboidDrawingMethod,
        );

        // Check if the canvas instance has an event for when drawing is completed.
        if (typeof canvasInstance.on === 'function') {
            canvasInstance.on('drawn', () => {
                canvasInstance.cancel(); // Disable further drawing after one line.
                canvasInstance.off('drawn'); // Remove the listener.
            });
        } else {
            console.error('Canvas instance does not support event subscription');
        }
    };

    public render(): JSX.Element {
        return (
            <CVATTooltip title='Draw a ruler line' placement='right'>
                <Icon className='cvat-fit-control' component={RulerIcon} onClick={this.onDraw} />
            </CVATTooltip>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DrawRulerControl);
